@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;600;700;900&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.range-slider{
  z-index: 0 !important;
}
*::marker {
  display: none !important;
}
.ais-SearchBox-input{
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0rem;
  width: calc(100% - 50px);
  font-size: 1rem;
  color: #000;
  outline: none;
  transition: all 0.3s;


}
.ais-SearchBox-submit{
  padding: 0.5rem 1rem;
  border: 1px solid #e5e5e5;
  background-color: #028322;
  color: #fff;
  border-radius: 0rem;
  width: 50px;
}
.ais-SearchBox-submitIcon{
  color: #fff;
  fill: #fff;
  width: 16px;
  height: 16px;
}