/* Example CSS classes for a fade-in animation */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms ease-in;
}

.scale-enter {
  opacity: 0;
  transform: scale(0.9);
}
.scale-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 150ms, transform 150ms;
}
.scale-exit {
  opacity: 1;
}
.scale-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 150ms, transform 150ms;
}

.slidingUp-bottom-enter {
  opacity: 0;
  transform: translateY(100%);
}
.slidingUp-bottom-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: opacity 300ms, transform 300ms;
}
.slidingUp-bottom-exit {
  opacity: 1;
}
.slidingUp-bottom-exit-active {
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 300ms, transform 300ms;
}


element-selector {
  /* CSS for range slider container */
}
element-selector[data-disabled] {
  /* CSS for disabled range slider element */
}
element-selector .range-slider__range {
  /* CSS for range */
}
element-selector .range-slider__range[data-active] {
  /* CSS for active (actively being dragged) range */
}
.range-slider .range-slider__thumb {
  background: #028322 !important;
  border-radius: 0 !important;
  z-index: 0;
}
.range-slider .range-slider__range {
  background: #359C4F !important;
}
.range-slider {
  border-radius: 0 !important;
}
element-selector .range-slider__thumb[data-lower] {
  /* CSS for lower thumb */
}
element-selector .range-slider__thumb[data-upper] {
  /* CSS for upper thumb */
}
element-selector .range-slider__thumb[data-active] {
  /* CSS for active (actively being dragged) thumbs */
}
element-selector .range-slider__thumb[data-disabled] {
  /* CSS for disabled thumbs */
}

.react-code-input > input{
  border: 1px solid #028322 !important;
  color: #028322 !important;
  width: 45px !important;
  height: 51px !important;
  text-align: center !important;
}